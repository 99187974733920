import React, { useState ,useEffect} from "react";
import "./header.css";
import { HiX, HiMenu } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCloseButtonTouched, setIsCloseButtonTouched] = useState(false);
  const [isCloseButtonHovered, setIsCloseButtonHovered] = useState(false);
  const location = useLocation();
  const isOurJourneyOpen = location.pathname === "/ourJourney";
  const isPeopleOpen = location.pathname === "/people";
  const isClientsOpen = location.pathname === "/clients";
  const isNewsOpen = location.pathname === "/news";
  const isInvestorsPartners = location.pathname === "/InvestorsPartners";
  const isNotFoundPage = location.pathname === "/NotFoundPage";

  const toggleSideNav = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsCloseButtonTouched(!isCloseButtonTouched);
    setIsCloseButtonHovered(false);
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMenuOpen]);
  return (
    <div>
     <nav
        className={`bg-nav-bar sticky-header ${
          isScrolled ? "navbar-active" : ""
        }`}
        
      >
        <div className="logo-container">
          <NavLink to="/">
            <img
              src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/GCRSLOgo.svg"
              alt="Logo"
              className="logo"
            />
          </NavLink>
        </div>
        <NavLink
          to="#"
          className={`menu-button ${
            isCloseButtonTouched ? "close-touched" : ""
          }`}
          onClick={toggleSideNav}
          onMouseEnter={() => setIsCloseButtonHovered(true)}
          onMouseLeave={() => setIsCloseButtonHovered(false)}
        >
          {isMenuOpen ? (
            <HiX
              size={30}
              style={{ color: isCloseButtonHovered ? "#bd0324" : "black" }}
            />
          ) : (
            <HiMenu
              size={30}
              style={{
                color:
                  isScrolled ||
                  isOurJourneyOpen ||
                  isPeopleOpen ||
                  isClientsOpen ||
                  isNewsOpen ||
                  isInvestorsPartners ||
                  isNotFoundPage
                    ? isCloseButtonHovered
                      ? "#bd0324"
                      : "#000"
                    : isCloseButtonHovered
                    ? "#bd0324"
                    : "#fff",
                transition: "color 0.3s ease",
              }}
              className="menu-icon"
            />
          )}
        </NavLink>
      </nav>
      {isMenuOpen && (
        <>
          <div className="overlay-header" onClick={toggleSideNav}></div>
          <div className="side-nav side-nav-open">
            <NavLink
              to="#"
              className={`menu-button ${
                isCloseButtonTouched ? "close-touched" : ""
              }`}
              onClick={toggleSideNav}
              onMouseEnter={() => setIsCloseButtonHovered(true)}
              onMouseLeave={() => setIsCloseButtonHovered(false)}
            >
              <HiX size={30} />
            </NavLink>
            <div className="scrollable-nav">
              <NavLink
              
                to="/"
                onClick={toggleSideNav}
                style={{ paddingTop: "10px" }}
              >
                Home
              </NavLink>
              <NavLink to="/aboutUs" onClick={toggleSideNav}>
                About Us
              </NavLink>
              <NavLink to="/people" onClick={toggleSideNav}>
                People
              </NavLink>
              <NavLink to="/capabilities" onClick={toggleSideNav}>
                Capabilities
              </NavLink>
              <NavLink to="/projects" onClick={toggleSideNav}>
                Projects & Case Studies
              </NavLink>
              <NavLink to="/products" onClick={toggleSideNav}>
                Solutions
              </NavLink>
              <NavLink to="/ourJourney" onClick={toggleSideNav}>
                Our Journey
              </NavLink>
              <NavLink to="/" style={{ color: "gray" }}>
                __
              </NavLink>
              <NavLink to="/clients" onClick={toggleSideNav}>
                <span className="hover-effect-header">Our Investors & Partners & Clients</span>
              </NavLink>
              {/* <NavLink to="/InvestorsPartners" onClick={toggleSideNav}>
                <span className="hover-effect-header">
                  Our Investors & Partners
                </span>
              </NavLink> */}
              <NavLink to="/commitments" onClick={toggleSideNav}>
                <span className="hover-effect-header">Commitments</span>
              </NavLink>
              <NavLink to="/randDintiatives" onClick={toggleSideNav}>
                <span className="hover-effect-header">R&D Initiatives</span>
              </NavLink>
              <NavLink to="/careers" onClick={toggleSideNav}>
                <span className="hover-effect-header">Careers</span>
              </NavLink>

              <NavLink to="/news" onClick={toggleSideNav}>
                <span className="hover-effect-header">News</span>
              </NavLink>
              <NavLink to="/contactUs" onClick={toggleSideNav}>
                <span className="hover-effect-header">Contact Us</span>
              </NavLink>
              <NavLink
                to="/NotFoundPage"
                onClick={toggleSideNav}
                style={{ display: "none" }}
              >
                <span className="hover-effect-header"></span>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Header;

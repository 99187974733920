/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer-top-area">
        <div className="container">
          <div className="row">
            {/* Column 1 */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single-widget">
                <a href="/">
                  <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/footer_icons/gcrslogo.svg" />
                </a>
                <p style={{ fontSize: "13px" }}>
                  GCRS is a solution provider, consultancy, and advisory
                  services firm that focuses on environmental and sustainability
                  challenges.
                </p>
                <ul className="icons">
                  {/* <li>
                  <a href="https://www.facebook.com/Geo-Climate-Risk-Solutions-106867784806906" target="_blank" rel="noopener noreferrer" >
                    <img src={require("../../src/assets/Group65.png")} style={{ height: "5vh",margin:"5px" }} className='wobble-vertical-on-hover'/>
                  </a>
                </li> */}
                  <li>
                    <a
                      href="https://instagram.com/gcrs_2014?igshid=YTQwZjQ0NmI0OA=="
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/footer_icons/Ellipse12.png"
                        style={{ height: "5vh", margin: "5px" }}
                        className="wobble-vertical-on-hover"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/geo-climate-risk-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/footer_icons/Ellipse13.png"
                        style={{ height: "5vh", margin: "5px" }}
                        className="wobble-vertical-on-hover"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC3pBDmo664dV8QAjYsSRpfA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/footer_icons/youtube.png"
                        style={{ height: "5vh", margin: "5px" }}
                        className=" wobble-vertical-on-hover"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Column 2 */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single-widget">
                <h3>Information</h3>
                <ul>
                  {/* <li>
                  <a href='/' style={{ textDecoration: "none", fontSize: "13px" }}>
                   <img src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/footer_icons/sidearrow.svg").default}  style={{marginRight:"10px"}} className="arrow-icon"/>
                   <span className="hover-effect">CIN No: U74930AP2014PTC095735</span>
                  </a>
                </li> */}
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      CIN No: U74930AP2014PTC095735
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      GSTIN: 37AAFCG8137J1Z9
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Start-up India Recognised: DIPP3449
                    </a>
                  </li>
                  {/* <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      D-U-N-S Number: 87-727-0861
                    </a>
                  </li> */}
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      <a
                        href="/Content/images/Events/GEO_CLIMATE_RISK_SOLUTIONS_PRIVATE_LIMITED.pdf"
                        download=""
                        style={{ color: "#fff", textDecoration: "none" }}
                      ></a>
                      ISO Certified: ISO 9001:2015
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                     Unique Entity ID: S6BYJWKHNMW8
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Column 3 */}
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="single-widget footer-text">
                <h3>Important Links</h3>
                <ul>
                  <li className="hover-effect">
                    <a
                      href="/"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Terms and Conditions
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/OurPrivacyPolicy"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Privacy Policy
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/Commitments"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Commitments
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/Clients"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Clients
                    </a>
                  </li>
                  <li className="hover-effect">
                    <a
                      href="/Capabilities"
                      style={{ textDecoration: "none", fontSize: "13px" }}
                    >
                      <img
                        src={
                          require("../../src/assets/gcrs_images/footer_icons/sidearrow.svg")
                            .default
                        }
                        style={{ marginRight: "10px" }}
                      />
                      Accreditation
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Column 4 */}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-widget">
                <h3 className="contactus-text">Contact Us</h3>
                <ul className="information" style={{ display: "flex" }}>
                  {/* <li className="address">
                    <img
                      src={
                        require("../../src/assets/gcrs_images/Home_icons/calling.svg")
                          .default
                      }
                      style={{ marginRight: "10px" }}
                    />
                    <a>Phone</a>
                    <br />
                    <a
                      href="/"
                      style={{
                        textDecoration: "none",
                        fontSize: "13px",
                        marginLeft: "30px",
                      }}
                    >
                      <span className="hover-phone-link">+91 9391779197</span>
                    </a>
                    <br />
                    <a
                      href="/"
                      style={{
                        textDecoration: "none",
                        fontSize: "13px",
                        marginLeft: "30px",
                      }}
                    >
                      <span className="hover-phone-link">+91 7997143593</span>
                    </a>
                  </li> */}
                  <li className="address" >
                    <img
                      src={
                        require("../../src/assets/gcrs_images/Home_icons/gmail.svg")
                          .default
                      }
                      style={{ marginRight: "10px" }}
                    />
                    <a>Email</a>
                    <br />
                    <a
                      href="/"
                      style={{
                        textDecoration: "none",
                        fontSize: "13px",
                        marginLeft: "30px",
                      }}
                    >
                      <span className="hover-phone-link">
                        business@gcrs.co.in
                      </span>
                    </a>
                    <br />
                    <a
                      href="/"
                      style={{
                        textDecoration: "none",
                        fontSize: "13px",
                        marginLeft: "30px",
                      }}
                    >
                      <span className="hover-phone-link">info@gcrs.co.in</span>
                    </a>
                    <br />
                    {/* <a href='/' style={{ textDecoration: "none", fontSize: "13px",marginLeft:"30px" }}><span className='hover-phone-link'>business.gcrs@gmail.com</span></a> */}
                  </li>
                </ul>
                <ul className="information">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={
                        require("../../src/assets/gcrs_images/Home_icons/mappointer.svg")
                          .default
                      }
                      style={{ marginRight: "10px", marginBottom: "12px" }}
                    />
                    <li className="address" style={{ fontSize: "13px" }}>
                      Innovation Valley, Hill-3, IT SEZ, Madhurawada,
                      Visakhapatnam - 530048
                    </li>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={
                        require("../../src/assets/gcrs_images/Home_icons/mappointer.svg")
                          .default
                      }
                      style={{ marginRight: "10px", marginBottom: "30px" }}
                    />
                    <li className="address" style={{ fontSize: "13px" }}>
                    TP-125, First floor, Technopark@iitk, Phase-I Building
                    Research and Development Centre, Indian Institute of Technology (IIT) Kanpur, Uttar Pradesh – 208016
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="copy-right">
            <p>
              Copyright © 2023 Geo Climate Risk Solutions Pvt. Ltd.
              {/* Uncomment the following line if you want to include the hit-counter */}
              {/* <span style={{ float: 'right' }}>Visitors: <a href="#"><img src="http://www.hit-counts.com/counter.php?t=MTQ1NzYyNQ==" alt="Visitors" /></a></span> */}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
